import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createRoot} from 'react-dom/client';
const container = document.getElementById('root');
import './assets/stylesheets/application.scss';
import Header from './app/containers/Header';
import Footer from './app/containers/Footer';

const root = createRoot(container);
root.render(<><Header/><div className='app-wrapper show-for-medium'/><App/><Footer/></>);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
