import React from 'react';

const Index = () =>
  <footer className='text-center shadow padding-vertical-2'>
    <div>Let us be social</div>
    <section>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          <div className='large-8 cell'>
            <ul className='menu align-center primary'>
              <li><a
                href='https://www.facebook.com/gsthethi2956034/'
                rel='noreferrer'
                target='_blank'>
                <i className='fa-brands fa-facebook'/>
              </a></li>
              <li><a
                href='https://www.instagram.com/realtor_gurdip'
                rel='noreferrer'
                target='_blank'>
                <i className='fa-brands fa-instagram'/>
              </a></li>
              <li><a
                href='https://www.linkedin.com/in/gurdip-thethi-8a4845103'
                rel='noreferrer'
                target='_blank'>
                <i className='fa-brands fa-linkedin'/>
              </a></li>
              <li><a
                href='https://wa.me/12042956034'
                rel='noreferrer'
                target='_blank'>
                <i className='fa-brands fa-whatsapp'/>
              </a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <p className='margin-top-1 dark-grey-color'>Made with <span className='alert-color'>
      <i className='fa-solid fa-heart'/></span> by Navneet</p>
  </footer>;

export default Index;
