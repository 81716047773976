import React from 'react';
import PersonalDetailsSection from '../PersonalDetailsSection';
import ROUTES from '../../../routes';
import personalImage from '../../../assets/images/GurdipThethi2.png';

const Index = () =>
  <>
    <section className=''>
      <div className='grid-container'>
        <div className='grid-x medium-up-2 align-middle'>
          <div className='cell margin-vertical-1 padding-right-3'>
            <p className='text-uppercase'>This is me</p>
            <h1 className='text-uppercase'>
          gurdip thethi
            </h1>
            <p>
              Welcome to my portfolio website! As a builder, I am passionate about creating beautiful,
              functional spaces that meet the unique needs and desires of my clients. Whether it&apos;s a new
              home, a renovation, or a commercial project, I take pride in delivering exceptional quality
              and craftsmanship.
            </p>
            <a
              className='button primary'
              href={ROUTES.ABOUT}>About Me</a>
          </div>
          <div className='cell padding-vertical-1 align-self-stretch'>
            <img
              src={personalImage}
              width='400px'/>
          </div>
        </div>
      </div>
    </section>
    <section className='bg-white gradient'>
      <div className='grid-container'>
        <PersonalDetailsSection/>
      </div>
    </section>
  </>;

export default Index;
