import image1 from '../Listings/6_Aspen_Lane.jpeg';

const Constructions = [
  {
    title: '6 Aspen Lane',
    image: image1,
    price: '1,000,000',
    path: 'https://www.realtor.ca/real-estate/25092210/17-aspen-lane-la-salle-rm-of-macdonald'
  }
];

export default Constructions;
