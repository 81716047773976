import React, {useEffect, useState} from 'react';
import realEstatePhoto from '../../../assets/images/House/real-estate-main-photo.jpg';
import realEstatePhoto2 from '../../../assets/images/House/real-estate-main-photo-2.jpg';
import realEstatePhoto3 from '../../../assets/images/House/real-estate-main-photo-3.jpg';
import modernIndoor from '../../../assets/images/House/modern-indoor.jpg';
import Card from '../../components/Card';
import Constructions from '../../../assets/images/Construction/constructions';

const Index = () => {
  const CAROUSEL_PHOTOS = [realEstatePhoto, realEstatePhoto2, realEstatePhoto3];
  let carouselTimer = null;
  const [carouselPhotoIndex, setCarouselPhotoIndex] = useState(0);

  const changePhoto = () => {
    setCarouselPhotoIndex((carouselPhotoIndex + 1) % CAROUSEL_PHOTOS.length);
  };

  useEffect(() => {
    carouselTimer = setTimeout(changePhoto, 5000);

    return function cleanup() {
      clearTimeout(carouselTimer);
    };
  }, [carouselPhotoIndex]);

  return <>
    <section>
      <div className='grid-container'>
        <div
          className='real-estate-wrapper'
          style={{backgroundImage: `url(${CAROUSEL_PHOTOS[carouselPhotoIndex]})`}}>
          <div className='real-estate-text'>
            <div className='name'>Home is where you feel loved and safe.</div>
            <div>Let&apos;s help you get one.</div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='grid-container'>
        <div className='padding-vertical-3'>
          <h1>REVOLUTIONIZING THE NEW HOUSING INDUSTRY</h1>
          <p>
            Great builders are known for their ability to create homes that are both functional
            and aesthetically pleasing. However, the truly remarkable builders are those who have
            revolutionized the field of home construction with their innovative ideas. One such
            builder is known for his unconventional approach to building homes that incorporates
            sustainable materials and energy-efficient systems. This builder&apos;s homes feature natural
            lighting, open floor plans, and built-in green spaces, all of which create
            a comfortable living environment that is also eco-friendly. By integrating
            cutting-edge technologies and creative designs, this builder has transformed the
            way we think about home construction, inspiring others to follow in his footsteps and
            create homes that are both beautiful and sustainable.
          </p>
        </div>
      </div>
    </section>
    <section
      className='parallax'
      style={{backgroundImage: `url(${modernIndoor})`}}/>
    <section className='padding-vertical-2'>
      <div className='grid-container'>
        <div>
          `<h2>My Projects</h2>
          <div className='grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 collapse'>
            {
              Constructions.length > 0
                ? Constructions.map(listing => <div
                  className='cell'
                  key={listing.title}>
                  <Card
                    image={listing.image}
                  />
                </div>)
                : <div>
                  <p className='houses-empty-state'>No Constructions in progress.</p>
                </div>
            }
          </div>
        </div>
        <div>
          <h2>
            On sale
          </h2>
          <p>
            To see all the open listings, Please <a
              className='listingsLink'
              href='https://realtor.ca/agent/1992681/gurdip-thethi-99-1780-wellington-avenue-winnipeg-manitoba-r3h1b3'
              rel='noreferrer'
              target='_blank'>
            Click here.</a>
          </p>
          {/* <div className='grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 collapse'>*/}
          {/*  {*/}
          {/*    Listings.length > 0*/}
          {/*      ? Listings.map(listing => <div*/}
          {/*        className='cell'*/}
          {/*        key={listing.title}>*/}
          {/*        <Card*/}
          {/*          image={listing.image}*/}
          {/*          path={listing.path}*/}
          {/*          price={listing.price}*/}
          {/*          title={listing.title}*/}
          {/*        />*/}
          {/*      </div>)*/}
          {/*      : <div>*/}
          {/*        <p className='houses-empty-state'>No Houses are listed.</p>*/}
          {/*      </div>*/}
          {/*  }*/}
          {/* </div>*/}
        </div>
      </div>
    </section>
  </>;
};

export default Index;
