import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import logo from '../../../assets/images/logo/black-named-logo-with-bg.png';
// import {useLocation} from 'react-router-dom';
import $ from 'jquery';
import OffCanvasMenu from '../../components/OffCanvasMenu';

const Index = () => {
  const BACKGROUND_CHANGE_SCROLL_OFFSET = 250;
  // const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [className, setClassname] = useState(null);
  const [isOffCanvasMenuShown, toggleOffCanvasMenu] = useState(false);

  const updateClassname = () => {
    if (document.documentElement.scrollTop >= BACKGROUND_CHANGE_SCROLL_OFFSET) {
      setClassname('primary');
    }
    else {
      setClassname(null);
    }
  };

  /*
   * useEffect(() => {
   *   toggleOffCanvasMenu(false);
   * }, [location]);
   */

  useEffect(() => {
    document.addEventListener('wheel', updateClassname);

    return () => {
      document.removeEventListener('wheel', updateClassname);
    };
  }, [updateClassname]);

  useEffect(updateClassname, []);

  useEffect(() => {
    const $mainContent = $('#main');

    if (isOffCanvasMenuShown && !$mainContent.hasClass('disable-scrolling')) {
      $mainContent.addClass('disable-scrolling');
    }
    else if (!isOffCanvasMenuShown) {
      $mainContent.removeClass('disable-scrolling');
    }
  }, [isOffCanvasMenuShown]);

  return <div>
    <OffCanvasMenu
      currentPath={location.pathname}
      isShown={isOffCanvasMenuShown}
      toggleOffCanvasMenu={toggleOffCanvasMenu}/>
    <div className='hide-for-large grid-x small-up-2'>
      <div className='cell logo-wrapper-small-screen'><a
        className='logo padding-0'
        href={ROUTES.ROOT}><img
          alt='logo'
          src={logo}
          width={70}/></a></div>
      <div
        className={`cell text-right hamburger ${isOffCanvasMenuShown ? 'hide' : 'visible'}`}
        onClick={() => {
          toggleOffCanvasMenu(true);
        }}>
        <i className='fa-solid fa-bars'/>
      </div>
    </div>
    <nav className='shadow position-fixed-top bg-white show-for-medium'>
      <div className='grid-container padding-0'>
        <div className='title-bar'>
          <div className='title-bar-left'>
            <ul className='height-100 menu'>
              <li className='logo-wrapper'><a
                className='logo padding-0'
                href={ROUTES.ROOT}><img
                  alt='logo'
                  src={logo}
                  width={70}/></a></li>
            </ul>
          </div>
          <div className='title-bar-right'>
            <ul className='menu height-100'>
              <li><a href={ROUTES.ROOT}>HOME</a></li>
              <li><a href={ROUTES.ABOUT}>ABOUT</a></li>
              <li><a href={ROUTES.REAL_ESTATE}>REAL ESTATE</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>;
};

export default Index;
