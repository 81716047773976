const ROUTES = {
  ABOUT: '/about',
  ROOT: '/',
  REAL_ESTATE: 'real-estate',
  MY_RESTAURANT: 'my-restaurant',
  CONSTRUCTION: 'construction',
  CONTACT: 'contact'
};

export default ROUTES;
