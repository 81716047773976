import React from 'react';
import PropTypes from 'prop-types';

const Card = ({image, title, price, path}) => <div>
  <a
    className='card has-background-image height-100 shadow'
    href={path}
    rel='noreferrer'
    target='_blank'>
    <div className='card-section'>
      <img src={image}/>
    </div>
    <div className={`card-divider ${(title || price) && 'background-color'}`}>
      {
        (title || price) && <>
          <div className='font-bold'>${price}</div>
          <div>{title}</div>
        </>
      }
    </div>
  </a>
</div>;

export default Card;

Card.propTypes = {
  image: PropTypes.any.isRequired,
  path: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string.isRequired
};
