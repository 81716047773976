import React from 'react';

const MyQualifications = () =>
  <div className='padding-vertical-2'>
    <h2 className='text-center padding-bottom-2'>My Story</h2>
    <div className='timeline'>
      <div className='container left'>
        <div className='content'>
          <h3 className='font-bold'>Real Estate</h3>
          {/* eslint-disable-next-line max-len */}
          <p>Check my
            <a
              className='primary-color font-bold'
              href='https://www.realtor.ca/agent/1992681/
              gurdip-thethi-99---1780-wellington-avenue-winnipeg-manitoba-r3h1b3'
              rel='noreferrer'
              target='_blank'> Listings.</a></p>
        </div>
      </div>
      <div className='container right'>
        <div className='content'>
          <h3 className='font-bold'>Builder</h3>
          <p>And I started building beautiful and cozy places for the lovely families of Manitoba</p>
        </div>
      </div>
      <div className='container left'>
        <div className='content'>
          <h3 className='font-bold'>Migrated to Canada</h3>
          <p>And my Life Changed</p>
        </div>
      </div>
      <div className='container right'>
        <div className='content'>
          <h3 className='font-bold'>Bachelors in Electrical Engineering</h3>
          <p>And I learnt the most interesting thing&apos;s of my life</p>
        </div>
      </div>
      <div className='container left'>
        <div className='content'>
          <h3 className='font-bold'>Secondary Education</h3>
          <p>I had all the fun of my life</p>
        </div>
      </div>
    </div>
  </div>;

export default MyQualifications;
