import React, {useEffect} from 'react';
import {Routes, Route, BrowserRouter, useLocation, useNavigationType} from 'react-router-dom';
import ROUTES from './routes';
import Home from './app/containers/Home';
import About from './app/containers/About';
import RealEstate from './app/containers/RealEstate';
import Contact from './app/containers/Contact';

const ScrollToTop = () => {
  const location = useLocation();
  const navType = useNavigationType();

  useEffect(() => {
    if (navType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return null;
};

const App = () =>
  <main>
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route
          element={<Home/>}
          path={ROUTES.ROOT}
        />
        <Route
          element={<About/>}
          path={ROUTES.ABOUT}
        />
        <Route
          element={<RealEstate/>}
          path={ROUTES.REAL_ESTATE}
        />
        {/* <Route*/}
        {/*  element={<Restaurant/>}*/}
        {/*  path={ROUTES.MY_RESTAURANT}*/}
        {/* />*/}
        <Route
          element={<Contact/>}
          path={ROUTES.CONTACT}
        />
      </Routes>
    </BrowserRouter>
  </main>;

export default App;
