import React from 'react';
import ROUTES from '../../routes';
import PropTypes from 'prop-types';

const OffCanvasMenu = ({toggleOffCanvasMenu, isShown}) =>
  <div className={`off-canvas-menu shadow ${isShown ? 'visible' : 'hide'}`}>
    <div
      className='cell text-right cross'
      onClick={() => {
        toggleOffCanvasMenu(false);
      }}>
      <i className='fa-solid fa-xmark'/>
    </div>
    <ul className=''>
      <li><a href={ROUTES.ROOT}>HOME</a></li>
      <li><a href={ROUTES.ABOUT}>ABOUT</a></li>
      <li><a href={ROUTES.REAL_ESTATE}>REAL ESTATE</a></li>
    </ul>
  </div>;

export default OffCanvasMenu;

OffCanvasMenu.propTypes = {
  isShown: PropTypes.bool.isRequired,
  toggleOffCanvasMenu: PropTypes.func.isRequired
};
