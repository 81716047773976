import React from 'react';
import PersonalDetailsSection from '../PersonalDetailsSection';
import MyQualifications from '../../components/MyQualifications';

const Index = () =>
  <>
    <section>
      <div className='grid-container'>
        <PersonalDetailsSection/>
      </div>
    </section>
    {/* <section className='gradient'>*/}
    {/*  <div className='grid-container'>*/}
    {/*    <h2 className='text-center'>Expertness</h2>*/}
    {/*    <div className='grid-x medium-up-4  padding-3 container__progressbars '>*/}
    {/*      <ProgressBar/>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/* </section>*/}
    <section className='gradient'>
      <div className='grid-container'>
        <MyQualifications/>
      </div>
    </section>
  </>;

export default Index;
