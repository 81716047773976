import React from 'react';
import ROUTES from '../../../routes';
import personalImage from '../../../assets/images/GurdipThethi.png';

const Index = () => <div>
  <div className='grid-x medium-up-2 align-middle padding-2'>
    <div className='cell padding-vertical-1 padding-right-3'>
      <img
        src={personalImage}
        width='400px'/>
    </div>
    <div className='cell'>
      <p className='text-uppercase'>About Me</p>
      <h1 className='text-uppercase'>
          Personal Details
      </h1>
      <p>
        With years of experience in the industry, I have honed my skills and developed a deep understanding
        of the construction process from start to finish. From project planning and design to materials
        selection and construction management, I am committed to providing my clients with a
        seamless and stress-free experience. Through this portfolio, I invite you to explore my
        past projects and see firsthand the level of detail and care that goes into each and every one.
        I believe that the quality of my work speaks for itself, and I am proud to showcase the homes
        and buildings that I have brought to life.
      </p>
      <a
        className='button primary'
        href={ROUTES.ABOUT}>View Full Details</a>
    </div>
  </div>
</div>;

export default Index;
